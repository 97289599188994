import window from "global";
import jwt_decode from "jwt-decode";
import libphonenumbers from "libphonenumbers";
import { resetAnalytics } from "./segment";

export const logout = () => {
  resetAnalytics();
  localStorage.clear();
  window.location = "/";
};

export const getCurrency = (currency = "USD") => {
  return currencyMap[currency];
};

export const parsePrice = ({ currency, price }) => {
  if (!price) {
    return "";
  }
  const curr = getCurrency(currency);
  return `${curr} ${price}`;
};

const currencyMap = {
  USD: "$",
  INR: "₹",
  AED: "$",
};

export const findInArray = (array, item) => array?.includes(item);
export const removeFromArray = (array, itemToRemove) =>
  array.filter((item) => item !== itemToRemove);

export const getLocalStorage = (key) => {
  return localStorage[key];
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const getPermissions = (token) => {
  try {
    const tokenValue = token || getLocalStorage("accessToken");
    const decodeToken = tokenValue ? jwt_decode(tokenValue) : null;
    return decodeToken ? decodeToken?.permissions.join(",") : "";
  } catch (error) {
    console.error("Error decoding or retrieving refresh token:", error);
    logout();
  }
};

export const getDate = (date, dateOnly) => {
  let parseDate = new Date(date);
  const dateStr = parseDate.toLocaleDateString("en-GB", {
    year: "numeric",
    day: "numeric",
    month: "short",
  });
  const time = parseDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return `${dateStr} ${dateOnly ? "" : `, ${time}`}`;
};
export const isDateExpired = (expiryDate, excludeToday) => {
  if (!expiryDate) {
    return "";
  }
  if (typeof expiryDate === "number" && expiryDate.toString().length === 10) {
    expiryDate = expiryDate * 1000;
  }
  const selectedDate = new Date(expiryDate);
  const today = new Date();
  return (
    selectedDate.setHours(23, 59, 59) -
    (excludeToday ? today.setHours(23, 59, 59) : today.setHours(0, 0, 0)) <
    0
  );
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const makeSentence = (str) => {
  if (!str) {
    return "";
  }
  const words = str.split("-").map((word) => {
    return capitalizeFirstLetter(word);
  });
  return words.join(" ");
};

export const sendDataToGoogleSheet = (path, params) => {
  const formData = new FormData();
  Object.keys(params).map((key) => {
    formData.append(key, params[key]);
  });
  fetch(path, {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: formData,
  });
};

export const openDeepLink = (url) => {
  var newUrl = url || "www.sahicoin.com/my-sahifolio";
  let os = localStorage.getItem("os");
  if (os === "ios") {
    // Trigger events
    // webkit.messageHandlers.triggerEvent.postMessage(JSON.stringify(event));
    // To open external url
    // webkit.messageHandlers.openExternalUrl.postMessage('https://apps.apple.com/us/app/sahicoin/id1592309488');
    // eslint-disable-next-line
    typeof webkit !== "undefined" &&
      // eslint-disable-next-line
      webkit.messageHandlers.primaryCtaClicked.postMessage(newUrl);
  } else {
    // eslint-disable-next-line
    typeof Android !== "undefined" && Android.primaryCtaClicked(newUrl);
  }
};

export const isMobile = () => {
  if (typeof window !== "undefined" && window.navigator) {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }
  return false;
};

export const getToken = (isWebView) => {
  if (typeof localStorage === "undefined") {
    return null;
  }
  if (isWebView) {
    return getLocalStorage("accessToken");
  }
  return getLocalStorage("accessToken");
};

export const getSubstring = (string, trimAt) => {
  return string.substring(0, trimAt) + "...";
};

export const getInitials = (name) => {
  if (!name) {
    return "";
  }
  const initialArray = name.split(" ").map((word) => word.substr(0, 1));
  return initialArray.join("");
};

export const scrollToTop = () =>
  window.scrollTo({ top: "10px", behavior: "smooth" });

export const ValidLangCountryCodes = ["en-us", "en-in", "en-ae"];

export const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== parseInt(0)) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

Array.prototype.shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const sortArray = (arrayToSort, keyToSort) => {
  if (arrayToSort?.length) {
    arrayToSort.sort((obj1, obj2) =>
      obj1[keyToSort] > obj2[keyToSort] ? 1 : -1
    );
  }
  return arrayToSort;
};

export const removeFromString = (text, elementToRemove) => {
  if (text) {
    let textArr = text.split(" ");
    // let removeElem = textArr.pop(elementToRemove);
    let newText = textArr.join(" ");
    return newText;
  }
  return text;
};

export const compareAppVersions = (oldVersion, newVersion) => {
  const status = oldVersion.localeCompare(newVersion, undefined, {
    numeric: true,
    sensitivity: "base",
  });
  switch (status) {
    case 0: {
      return "";
    }
    case 1: {
      return oldVersion;
    }
    case -1: {
      return newVersion;
    }
    default: {
      return "";
    }
  }
};

export const getTimeDiff = (timeInEpoch, reverse) => {
  if (!timeInEpoch) {
    return "";
  }
  let today = Date.now();
  return Math.floor(reverse ? today - timeInEpoch : timeInEpoch - today);
};

export const getRemainingTime = (date) => {
  if (!date) {
    return "";
  }
  let d2 = new Date(date).getTime();
  let timeDiff = getTimeDiff(d2);
  let days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  let hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  let timeLeft = "";
  if (days > 0) {
    timeLeft = `${timeLeft} ${days} ${days > 1 ? `days` : `day`}`;
  }
  if (hours > 0) {
    timeLeft = `${timeLeft} ${hours} ${hours > 1 ? "hrs" : "hr"}`;
  }
  if (minutes > 0) {
    timeLeft = `${timeLeft} ${minutes} ${minutes > 1 ? "mins" : "min"} `;
  }
  return timeLeft;
};

export const getElapsedTime = (date) => {
  if (!date) {
    return "";
  }
  let d2 = new Date(date).getTime();
  let timeDiff = getTimeDiff(d2, true);
  let hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  if (hours > 0) {
    return `${hours} ${hours > 1 ? "hrs" : "hr"}`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes > 1 ? "mins" : "min"}`;
  }
};

let speed = 50;

export const handleNmberAnimation = (id, startNum, rev) => {
  let elt = document.getElementById(id);
  if (!elt) {
    return false;
  }
  let endNbr = Number(elt.innerHTML);

  if (rev) {
    handleNmberAnimationRecRev(startNum || 0, endNbr, elt);
  } else {
    handleNmberAnimationRec(startNum || 0, endNbr, elt);
  }
};

const handleNmberAnimationRec = (i, endNbr, elt) => {
  if (i <= endNbr) {
    elt.innerHTML = i;
    setTimeout(() => {
      handleNmberAnimationRec(i + 1, endNbr, elt);
    }, speed);
  }
};

const handleNmberAnimationRecRev = (i, endNbr, elt) => {
  if (i >= endNbr) {
    elt.innerHTML = i;
    setTimeout(() => {
      handleNmberAnimationRecRev(i - 1, endNbr, elt);
    }, speed);
  }
};

const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const imagetoblob = (ImageURL) => {
  // var ImageURL = document.getElementById(ImgId).getAttribute('src');
  // Split the base64 string in data and contentType
  var block = ImageURL.split(";");
  // Get the content type of the image
  var contentType = block[0].split(":")[1]; // In this case "image/gif"
  // get the real base64 content of the file
  var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

  // Convert it to a blob to upload
  return b64toBlob(realData, contentType);
};

export const modifyPrice = (price) =>
  `${price < 0 ? "-" : ""}$${price < 0 ? -price : price}`;

window.refreshPage = function () {
  window.location.reload();
};

export const validatePhoneNumber = ({ phone, countryCode }) => {
  const phoneUtil = libphonenumbers.PhoneNumberUtil.getInstance();
  const number = phoneUtil.parseAndKeepRawInput(phone, countryCode);

  return phoneUtil.isValidNumber(number);
};

export const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const EXCHANGE_CODE = {
  BITBNS: "bitbns",
  BINANCE: "binance",
  HUOBI: "huobi",
  COINDCX: "coindcx",
  WAZIRX: "wazirx",
  COINBASE_PRO: "coinbasepro",
};

const allowedExchanges = [EXCHANGE_CODE.BINANCE];

export const isExchangeConnected = (list) => {
  if (!(list && list.length)) {
    return false;
  }
  return !allowedExchanges.every((allowedExchange) => {
    const matchedExchange = list.find(
      (exchange) => exchange.code === allowedExchange
    );
    return matchedExchange.connected === false;
  });
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const getMonthString = (monthNumber) => {
  return months[monthNumber];
};

export const findInString = ({ actualString = "", findThis = "" }) => {
  return actualString.includes(findThis);
};

export const isNumber = (inputValue) => {
  const numericPattern = /^[0-9]+$/;
  return numericPattern.test(inputValue);
};

export const splitString = ({ actualString, splitBasedOn }) => {
  let newString = actualString.replace(splitBasedOn, " ");
  return newString;
};

export const getRandomArrayItem = (arr) => {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
};

export const isNotEmptyObject = (obj) => {
  if (obj !== null && obj !== undefined) {
    return Object.values(obj).length > 0;
  }
};

// export const handleAppModalOpen = (modalName = "modalOpen") => {
//   const urlParams = new URLSearchParams(window.location.search);
//   urlParams.set(modalName, "true");
//   const updatedUrl = `${window.location.origin}${
//     window.location.pathname
//   }?${urlParams.toString()}`;
//   window.location.href = updatedUrl;
//   // window.history.pushState({ path: updatedUrl }, "", updatedUrl);
//   // history.push(updatedUrl);
// };

// export const handleAppModalClose = (modalName = "modalOpen") => {
//   const urlParams = new URLSearchParams(window.location.search);
//   urlParams.delete(modalName);

//   const updatedUrl = `${window.location.origin}${
//     window.location.pathname
//   }?${urlParams.toString()}`;
//   window.location.href = updatedUrl;
//   // window.history.pushState({ path: updatedUrl }, "", updatedUrl);
//   // history.push(updatedUrl);
// };

export const allowedIP =
  "43.204.184.175 43.204.120.205 13.244.252.161 108.137.197.220 3.29.8.176 15.184.136.22 13.209.138.143 52.74.105.64";

export const handleScrollToSection = ({ sectionId, topSpace = 50 }) => {
  const sectionElement = document?.getElementById(sectionId);

  if (sectionElement) {
    const offset = sectionElement.offsetTop - topSpace;
    window.scrollTo({
      top: offset,
      behavior: "smooth",
    });
  }
};

export const formatCurrency = (number, roundingOption, removesign) => {
  if (typeof number !== "number" || isNaN(number)) {
    return null;
  }

  if (roundingOption !== "roundup" && roundingOption !== "rounddown") {
    return 'Invalid rounding option. Please provide "roundup" or "rounddown".';
  }

  const roundedNumber =
    roundingOption === "roundup"
      ? Math.ceil(number * 100) / 100
      : Math.floor(number * 100) / 100;
  const formattedNumber = roundedNumber.toFixed(2);

  return removesign
    ? formattedNumber.replace(/\d(?=(\d{3})+\.)/g, "$&,")
    : "$" + formattedNumber.replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const convertMonthCodeToString = (monthCode) => {
  const year = Math.floor(monthCode / 100);
  const month = monthCode % 100;

  if (month < 1 || month > 12) {
    return "Invalid Date";
  }

  const monthString = new Intl.DateTimeFormat("en-US", {
    month: "short",
  }).format(new Date(year, month - 1, 1));

  return `${monthString} ${year}`;
};

export const isHBG = (strategyCode) => {
  return strategyCode.startsWith('HBG')
};

export const isHDG = (strategyCode) => {
  return strategyCode.startsWith('HDG');
};

export const isOxido = (strategyCode) => {
  return strategyCode.startsWith('OXIDO_BTC');
};


export const showFactSheet = (strategyCode) => {
  return isHBG(strategyCode) || isHDG(strategyCode);
}
